/**
 * valido i form sulla base del validatore
 * @param a
 * @param b
 */
export function validateForm(form, rules) {

    var valid = true;

    Object.keys(rules).forEach(key => {

        if (rules[key].required) {
            valid = valid && (form[key] != "");
        }

    })

    return valid;

}