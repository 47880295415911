import React from "react";

import {BigBreadcrumbs, WidgetGrid} from "../../../../common";
import Article from "./Article";
import UserListTable from "./UserListTable";
import {connect} from "react-redux";

class UserPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            pageTitle: ["Utenti", "lista"],
            action: "",
            widgetTitle: "",
            form: <div></div>,
            user: props.user
        }
    }

    componentWillMount() {

        let action = this.props.match.path.split('/')[2];

        this.setState({action: action});

        switch (action) {
            case "add":
                break;
            case "edit":
                break;
            case "list":
                this.setState({pageTitle: ["Utenti", "Lista"]});
                this.setState({widgetTitle: "Lista utenti"});
                this.setState({
                    form:
                        <div className="row">
                            <Article widgetTitle={this.state.widgetTitle} className="col-sm-12 col-md-12 col-lg-12">
                                <UserListTable user={this.state.user}/>
                            </Article>
                        </div>
                });
                break;
        }
    }

    render() {

        return (
            <div id="content">
                <div className="row">
                    <BigBreadcrumbs
                        items={this.state.pageTitle}
                        icon="fa fa-fw fa-glass"
                        className="col-xs-12 col-sm-9 col-md-9 col-lg-9"
                    />

                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        {/* Button trigger modal */}
                        {/*<a onClick="openModal()" className="btn btn-success btn-lg pull-right header-btn hidden-mobile"><i
              className="fa fa-circle-arrow-up fa-lg"/> Launch form modal</a>*/}
                    </div>
                </div>

                <WidgetGrid>
                    {this.state.form}
                </WidgetGrid>

            </div>
        );
    }
}

export default connect((state) => {
    return {user: state.user}
})(UserPage);

