import React, {useRef, useEffect, useState} from "react";
import {BigBreadcrumbs, JarvisWidget, WidgetGrid} from "../../../../common";
import Datatable from "../../../../common/tables/components/Datatable";
import AppSettings from "../../../../config/AppSettings";
import {renderToString} from 'react-dom/server'

import $ from 'jquery';
import AbjectiveForm from "./AbjectiveForm";

export default function AbjectivePage(props) {

    var table = useRef(null);

    useEffect(() => {


    }, [props.match.params.id])


    const reload = () => {
        $(table.current.refs.table).DataTable().ajax.reload()
    }

    return (
        <div id="content">
            <div className="row">
                <BigBreadcrumbs
                    items={["Aggettivi",props.match.params.id ? "Modifica" : "Aggiungi"]}
                    icon="fa fa-fw fa-bullseye"
                    className="col-xs-12 col-sm-9 col-md-9 col-lg-9"
                />

                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                    {/* Button trigger modal */}
                    {/*<a onClick="openModal()" className="btn btn-success btn-lg pull-right header-btn hidden-mobile"><i
              className="fa fa-circle-arrow-up fa-lg"/> Launch form modal</a>*/}
                </div>
            </div>
            {/* widget grid */}
            <WidgetGrid>
                {/* START ROW */}

                <div className="row">
                    {/* NEW COL START */}
                    <article className="col-sm-12 col-md-12 col-lg-12">
                        {/* Widget ID (each widget will need unique ID)*/}
                        <JarvisWidget
                            editbutton={false}
                            custombutton={false}
                            id="wid-id-0">
                            <header>

                            </header>

                            {/* widget div*/}
                            <div>
                                {/* widget content */}
                                <div className="widget-body no-padding">
                                    <AbjectiveForm id={props.match.params.id} reload={reload}/>
                                </div>
                                {/* end widget content */}
                            </div>
                            {/* end widget div */}
                        </JarvisWidget>
                        {/* end widget */}

                    </article>
                    {/* END COL */}

                </div>
            </WidgetGrid>

            <WidgetGrid>
                <div className="row">
                    <article className="col-sm-12">
                        <JarvisWidget id="wid-id-0" editbutton={false} color="darken">
                            <header>

                            </header>
                            <div>
                                <div className="widget-body no-padding">
                                    <Datatable ref={table}
                                               options={{
                                                   ajax: AppSettings.url + "/api/abjectives",
                                                   columns: [
                                                       {
                                                           data: null,
                                                           className: "center",
                                                           render: function (data, type, row) {

                                                               return renderToString(<a
                                                                   class='text-center'
                                                                   href={"/#/abjectives/edit/" + data.id}>
                                                                   <i class='fa fa-2x fa-edit'></i>
                                                               </a>)
                                                           }
                                                       },
                                                       {data: "id"},
                                                       {data: "name"},
                                                       {data: "context"},
                                                       {data: "typology"},
                                                       {data: "description"},
                                                       {data: "updated_at"}
                                                   ],
                                                   order: [
                                                       [6, "desc"]],
                                                   reset: true
                                               }}

                                               paginationLength={true}
                                               className="table table-striped table-bordered table-hover"
                                               width="100%">
                                        <thead>
                                        <tr>
                                            <th>
                                                <i className="fa fa-fw fa-edit text-muted hidden-md hidden-sm hidden-xs"/>
                                                Modifica
                                            </th>
                                            <th>
                                                <i className="fa fa-fw fa-tag text-muted hidden-md hidden-sm hidden-xs"/>
                                                ID
                                            </th>
                                            <th>
                                                <i className="fa fa-fw fa-tag text-muted hidden-md hidden-sm hidden-xs"/>
                                                Nome
                                            </th>
                                            <th>
                                                <i className="fa fa-fw  txt-color-blue hidden-md hidden-sm hidden-xs"/>
                                                Contesto
                                            </th>
                                            <th>
                                                <i className="fa fa-fw  txt-color-blue hidden-md hidden-sm hidden-xs"/>
                                                Tipologia
                                            </th>
                                            <th>
                                                <i className="fa fa-fw  txt-color-blue hidden-md hidden-sm hidden-xs"/>
                                                Descrizione
                                            </th>
                                            <th data-class="expand">
                                                <i className="fa fa-fw fa-calendar text-muted hidden-md hidden-sm hidden-xs"/>
                                                Utlimo aggiornamento
                                            </th>
                                        </tr>
                                        </thead>
                                    </Datatable>
                                </div>
                            </div>
                        </JarvisWidget>
                    </article>
                </div>
            </WidgetGrid>
        </div>
    );

}
