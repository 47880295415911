import React from "react";

export default class Footer extends React.Component {
    render() {
        return (
            <div className="page-footer">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <span className="txt-color-white">
                          Tastingnotes 0.0.19 - development version
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
