import React from "react";
import JarvisWidget from "../../../../common/widgets/components/JarvisWidget";

export default class Article extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <article className={this.props.className}>
                {/* Widget ID (each widget will need unique ID)*/}
                <JarvisWidget
                    editbutton={false}
                    custombutton={false}
                    id="wid-id-0">
                    <header>
                        <span className="widget-icon">
                            <i className={this.props.widgetIconClass}/>
                        </span>
                        <h2>{this.props.widgetTitle}</h2>
                    </header>

                    {/* widget div*/}
                    <div>
                        {/* widget content */}
                        <div className="widget-body">
                            {this.props.children}
                        </div>
                        {/* end widget content */}
                    </div>
                    {/* end widget div */}
                </JarvisWidget>
                {/* end widget */}

            </article>
        )
    }

}