export const REQUEST_USER = "REQUEST_USER";
export const USER_INFO = "USER_INFO";

export function requestUserInfo() {

    var user = localStorage.getItem('user');
    var data = JSON.parse(user)

    return dispatch => {
        return dispatch({
            type: USER_INFO,
            payload: data
        });
    };
}


