import React, {useState} from "react";
import ToggleMenu from "./ToggleMenu";
import {Redirect, Route, useLocation} from "react-router";


export default function Header(props) {

    const logout = (e) => {
        e.preventDefault();
        localStorage.removeItem('user');
        window.location.href = "/#/login";
    }

    return (
        <header id="header">
            <div id="logo-group">
          <span id="logo">
            <img
                src="assets/img/logo.png" // place your logo here
                alt="SmartAdmin"
            />
          </span>
                {/* Note: The activity badge color changes when clicked and resets the number to 0
         Suggestion: You may want to set a flag when this happens to tick off all checked messages / notifications */}


            </div>

            <div className="pull-right" /*pulled right: nav area*/>
                <ToggleMenu
                    className="btn-header pull-right" /* collapse menu button */
                />

                {/* #MOBILE */}
                {/*  Top menu profile link : this shows only when top menu is active */}
                <ul
                    id="mobile-profile-img"
                    className="header-dropdown-list hidden-xs padding-5"
                >
                    <li className="">
                        <a href="#/"
                           className="dropdown-toggle no-margin userdropdown"
                           data-toggle="dropdown"
                        >
                            <img
                                src="assets/img/avatars/sunny.png"
                                alt="John Doe"
                                className="online"
                            />
                        </a>
                        <ul className="dropdown-menu pull-right">
                            <li>
                                <a href="#/" className="padding-10 padding-top-0 padding-bottom-0">
                                    <i className="fa fa-cog"/> Setting
                                </a>
                            </li>
                            <li className="divider"/>
                            <li>
                                <a
                                    href="#/views/profile"
                                    className="padding-10 padding-top-0 padding-bottom-0"
                                >
                                    <i className="fa fa-user"/>
                                    <u>P</u>rofile
                                </a>
                            </li>
                            <li className="divider"/>
                            <li>
                                <a href="#/"
                                   className="padding-10 padding-top-0 padding-bottom-0"
                                   data-action="toggleShortcut"
                                >
                                    <i className="fa fa-arrow-down"/> <u>S</u>hortcut
                                </a>
                            </li>
                            <li className="divider"/>
                            <li>
                                <a href="#/"
                                   className="padding-10 padding-top-0 padding-bottom-0"
                                   data-action="launchFullscreen"
                                >
                                    <i className="fa fa-arrows-alt"/> Full
                                    <u>S</u>creen
                                </a>
                            </li>
                            <li className="divider"/>
                            <li>
                                <a
                                    href="#/login"
                                    className="padding-10 padding-top-5 padding-bottom-5"
                                    data-action="userLogout"
                                >
                                    <i className="fa fa-sign-out fa-lg"/>
                                    <strong>
                                        <u>L</u>ogout
                                    </strong>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                {/* logout button */}
                <div id="logout" className="btn-header transparent pull-right">
                    <span>
                        <a href="javascript:void(0)"
                           onClick={logout}
                           title="Sign Out"
                           data-logout-msg="You can improve your security further after logging out by closing this opened browser">
                            <i className="fa fa-sign-out"/>
                        </a>
                    </span>
                </div>

            </div>
            {/* end pulled right: nav area */}
        </header>
    );

}
