import React from "react";
import {Tooltip} from "react-bootstrap";

const tooltip = (
    <Tooltip id="reset-widgets-suggestion">
        <i className="text-warning fa fa-warning"/> Warning! This will reset all
        your widget settings.
    </Tooltip>
);

export default class Ribbon extends React.Component {
    render() {
        return (
            <div id="ribbon">
                <span className="ribbon-button-alignment" style={{color: "white"}}>
                    Pannello di amministrazione tasting note
                </span>
            </div>
        );
    }
}
