import Loadable from "react-loadable";
import {Loading} from "../../common/navigation";

const Dashboard = Loadable({
    loader: () => import("./components/Dashboard"),
    loading: Loading
});

export const routes = [
    {
        path: "/",
        exact: true,
        name: "Dashboard",
        component: Dashboard
    },
];
