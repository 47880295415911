import React from "react";

class AppSettings {

    constructor() {

        if (window.location.hostname == "localhost") {
            this.url = 'http://localhost:8000';
            this.origin = "http://localhost:3000";
            this.authToken = this.url + "/oauth/token";
            this.grant_type = "authorization_code";
            this.authorizeUrl = this.url + "/oauth/authorize";
            this.client_id = 3
            this.client_secret = "GZRzlvwJ8Z0KdqCGChi0V3mmDUOprWFtwy0KmMS1";
            this.redirect_uri = this.url + "/callback";
            this.response_type = 'code';
            this.refresh_token = "";
            this.scope = "";
            this.code = "1000";
        } else {
            this.url = 'https://api.tastingnotes.it'
            this.origin = 'https://admin.tastingnotes.it'
            this.authToken = this.url + "/oauth/token";
            this.grant_type = "authorization_code";
            this.authorizeUrl = this.url + "/oauth/authorize";
            this.client_id = 3
            this.client_secret = "GZRzlvwJ8Z0KdqCGChi0V3mmDUOprWFtwy0KmMS1";
            this.redirect_uri = this.url + "/callback";
            this.response_type = 'code';
            this.refresh_token = "";
            this.scope = "";
            this.code = "1000";
        }
    }
}


export default (new AppSettings());