import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";

import {composeWithDevTools} from "redux-devtools-extension";

import {config} from "../config/config";
import {dumpLayoutToStorage, handleBodyClasses, layoutInit, layoutReducer} from "../common/layout";

import {requestUserInfo, userReducer} from "../common/user";
import {navigationReducer} from "../common/navigation";
import {chatInit, chatReducer} from "../common/chat";
import {eventsReducer} from "../common/calendar";
import {todoReducer} from "../common/todo";
import {i18nInit, i18nReducer} from "../common/i18n";
import {outlookReducer} from "../views/outlook";

import {voiceControlOn, VoiceMiddleware, voiceReducer} from "../common/voice-control";

export const rootReducer = combineReducers({
    layout: layoutReducer,
    navigation: navigationReducer,
    outlook: outlookReducer,
    user: userReducer,
    chat: chatReducer,
    events: eventsReducer,
    voice: voiceReducer,
    todo: todoReducer,
    i18n: i18nReducer
});

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(
            thunk,
            handleBodyClasses,
            dumpLayoutToStorage,
            VoiceMiddleware
        )
    )
);

store.dispatch(layoutInit());
store.dispatch(chatInit());
store.dispatch(requestUserInfo());
store.dispatch(i18nInit());


if (config.voice_command_auto) {
    store.dispatch(voiceControlOn());
}

export default store;
