import React, {useEffect, useState} from "react";


export default function BigBreadcrumbs(props) {

    useEffect(() => {

    }, [props.items, props.icon]);


    const [first, ...tail] = props.items;

    return (
        <div className={props.className + " big-breadcrumbs"}>
            <h1 className="page-title txt-color-blueDark">
                <i className={props.icon}/>
                {" " + first}
                {tail.map((item, idx) => {
                    return (
                        <span key={"big-breadcrumb-" + idx}>
                <span className="page-title-separator">&gt;</span>
                            {item}
              </span>
                    );
                })}
            </h1>
        </div>
    );

}
