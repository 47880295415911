import React from "react";
import ToggleShortcut from "./ToggleShortcut";

import {connect} from "react-redux";

function LoginInfo(props) {

    return (
        <div className="login-info">
        <span>
          <ToggleShortcut>
              {props.user.picture ? <img src={props.user.picture} alt="me" className="online"/> : null}
              <span>{props.user.name}</span>
          </ToggleShortcut>
        </span>
        </div>
    );

}


export default connect((state) => {
    return {user: state.user}
})(LoginInfo);
