import {USER_INFO} from "./UserActions";

export function userReducer(state = {
                                name: null,
                                picture: null,
                                id: null,
                                email: null,
                            },
                            action) {
    switch (action.type) {
        case USER_INFO:
            return {
                ...action.payload
            };
        default:
            return state;
    }
}
