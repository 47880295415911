import Loadable from "react-loadable";
import {Loading} from "../../common/navigation";
import UserPage from "./components/layouts/UserPage";
import AbjectivePage from "./components/layouts/AbjectivePage";


const WinePage = Loadable({
    loader: () => import("./components/layouts/WinePage"),
    loading: Loading
});

const CellarPage = Loadable({
    loader: () => import("./components/layouts/CellarPage"),
    loading: Loading
});

const TreePage = Loadable({
    loader: () => import("./components/layouts/trees/TreePage"),
    loading: Loading
});

const TermPage = Loadable({
    loader: () => import("./components/layouts/trees/TermPage"),
    loading: Loading
});

const VitigniPage = Loadable({
    loader: () => import("./components/layouts/VitigniPage"),
    loading: Loading
});

const TastingPage = Loadable({
    loader: () => import("./components/layouts/TastingPage"),
    loading: Loading
});


export const routes = [
    {
        path: "/wines/add",
        exact: true,
        component: WinePage,
        name: "Aggiungi vino"
    },
    {
        path: "/wines/edit/:id",
        exact: true,
        component: WinePage,
        name: "Modifica vino"
    },
    {
        path: "/wines/list",
        exact: true,
        component: WinePage,
        name: "Lista vini"
    },
    {
        path: "/vitigni",
        exact: true,
        component: VitigniPage,
        name: "Vitigni"
    },
    {
        path: "/vitigni/edit/:id",
        exact: true,
        component: VitigniPage,
        name: "Vitigni"
    },
    {
        path: "/cellars/add",
        exact: true,
        component: CellarPage,
        name: "Aggiungi cantina"
    },
    {
        path: "/cellars/edit/:id",
        exact: true,
        component: CellarPage,
        name: "Modifica cantina"
    },
    {
        path: "/cellars/list",
        exact: true,
        component: CellarPage,
        name: "Lista Cantine"
    },
    {
        path: "/trees/:type",
        exact: true,
        component: TreePage,
        name: "Aromas"
    },
    {
        path: "/abjectives",
        exact: true,
        component: AbjectivePage,
        name: "Terms"
    },
    {
        path: "/abjectives/edit/:id",
        exact: true,
        component: AbjectivePage,
        name: "Terms"
    },
    {
        path: "/terms",
        exact: true,
        component: TermPage,
        name: "Terms"
    },
    {
        path: "/terms/edit/:id",
        exact: true,
        component: TermPage,
        name: "Terms"
    },
    {
        path: "/tasting/:action",
        exact: true,
        component: TastingPage,
        name: "Terms"
    },
    {
        path: "/tasting/:action/:id",
        exact: true,
        component: TastingPage,
        name: "Terms"
    }, {
        path: "/user/list",
        exact: true,
        component: UserPage,
        name: "User"
    }
]
