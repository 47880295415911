import React, {useState, useEffect, useRef} from "react";
import AppSettings from "../../../../config/AppSettings.js";
import axios from "axios";
import UiValidate from "../../../../common/forms/validation/UiValidate";
import {smallBox} from "../../../../common/utils/functions";
import Loading from "../../../../common/navigation/components/Loading";

import TastingTecnicalConfig from './TastingTecnicalConfig';
import {validateForm} from "../Utility";

const validationOptions = {
    // Rules for form validation
    rules: {
        name: {
            required: true,
        }
    },

    // Messages for form validation
    messages: {
        name: {
            required: "Inserisci una o più parole"
        }
    }
};

const extractContext = () => {

    return TastingTecnicalConfig.map((item) => {
        return {
            name: item.title,
            value: item.context
        }
    });

}

const extractTypologies = () => {

    let obj = {};

    for (let c of TastingTecnicalConfig) {
        obj = {...obj, [c.context]: c.typologies};
    }

    return obj;

}

const config = {
    contexts: extractContext(TastingTecnicalConfig),
    typologies: extractTypologies(TastingTecnicalConfig),

    typology: (contextValue) => {
        return config.typologies[contextValue][0].value;
    }
}

export default function AbjectiveForm(props) {

    const [name, setName] = useState("");
    const [context, setContext] = useState(config.contexts[0].value);
    const [typology, setTypology] = useState(config.typology(context));
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(null)

    var myFormRef = useRef(null);

    useEffect(() => {

        if (props.id != id) {
            axios.get(AppSettings.url + "/api/abjectives/" + props.id).then(result => {

                setName(result.data.name);
                setContext(result.data.context);
                setTypology(result.data.typology);
                setDescription(result.data.description == null ? "" : result.data.description)

                setId(props.id);
                setLoading(false);
            });
        }


    }, [props.id, context, loading, typology, name, description])


    const changeField = (e) => {


        if (e.target != undefined) {

            var fieldName = e.target.name;
            var fieldValue = e.target.value;

            switch (fieldName) {

                case 'name':
                    setName(fieldValue);
                    break;
                case 'context':
                    setContext(fieldValue);
                    setTypology(config.typology(fieldValue))
                    break;
                case 'typology':
                    setTypology(fieldValue);
                    break;
                case 'description':
                    setDescription(fieldValue);
                    break;

            }

        }

    }

    const handleSubmit = (e) => {

        e.preventDefault();

        const data = {
            name: name,
            context: context,
            typology: typology,
            description: description
        }

        if (validateForm(data, validationOptions.rules)) {

            let uri = AppSettings.url + '/api/abjectives';
            let call = null;

            if (props.id) {
                uri += "/" + props.id;
                call = axios.put(uri, data);
            } else {
                call = axios.post(uri, data);
            }

            setLoading(true);

            call.then(response => {

                setLoading(false);

                smallBox({
                    title: props.id ? "Modifica termine" : "Inserimento termine",
                    content: "<i>Avvenuta con successo</i>",
                    color: "green",
                    iconSmall: "fa fa-thumbs-up bounce animated",
                    timeout: 4000
                });


                if (!props.id) {
                    myFormRef.current.reset();
                    setName('');
                    setDescription('');
                }

                props.reload();

            }).catch(error => {

                setLoading(false);

                smallBox({
                    title: props.id ? "Modifica termine fallito" : "Inserimento termine fallito",
                    content: error.response.data.message,
                    color: "red",
                    iconSmall: "fa fa-thumbs-down bounce animated",
                    timeout: 4000
                });


            });

        }
    }


    if (loading) {
        return <Loading/>
    }

    const buildOption = (list, name, value) => {

        if (list == undefined || list.length == 0) {
            return <select disabled={true}>
                <option value="">Non necessario</option>
            </select>;
        }

        return <select name={name} onChange={changeField} value={value}>
            {list.map((item) => {
                return <option value={item.value}>{item.name}</option>
            })}
        </select>
    }

    return (
        <UiValidate options={validationOptions}>
            <form ref={myFormRef}
                  id="abjective-form"
                  className="smart-form"
                  noValidate="novalidate"
                  onSubmit={handleSubmit}>
                <header>Dati aggettivo</header>
                <fieldset>
                    <div class="row">
                        <section className="col col-6">
                            <section className="col col-lg-12 col-md-12">
                                <label className="input">
                                    <label className="label">Nome</label>
                                    <input required type="text" name="name" onChange={changeField}
                                           value={name}/>
                                </label>
                            </section>
                            <section className="col col-6">
                                <label className="label">Contesto</label>
                                <label className="select">
                                    {buildOption(config.contexts, "context", context)}
                                    <i></i>
                                </label>
                            </section>
                            <section className="col col-6">
                                <label className="label">Tipologia</label>
                                <label className="select">
                                    {buildOption(config.typologies[context], "typology", typology)}
                                    <i></i>
                                </label>
                            </section>
                        </section>
                        <section className="col col-6">
                            <label className="label">Descrizione (per ricerca)</label>
                            <label className="textarea">
                                <textarea name="description" onChange={changeField} rows="10" className="custom-scroll"
                                          value={description}/>
                            </label>
                        </section>


                    </div>
                </fieldset>
                <footer>
                    <button type="submit" className="btn btn-primary">
                        {props.id ? "Salva" : "Aggiungi"}
                    </button>
                    {props.id ? (
                        <div onClick={() => window.location.href = '/#/abjectives/'}
                             className="btn btn-default">
                            Anulla
                        </div>
                    ) : <></>}
                </footer>

            </form>
        </UiValidate>
    );
}
