import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";

import {Navigation, Shortcut} from "../../navigation";
import Ribbon from "./Ribbon";

import Header from "./Header";
import Footer from "./Footer";

import {routes} from "../../../routes";

export default function Layout(props) {
    return (
        <div>
            <Header/>
            <Navigation/>
            <div id="main" role="main">
                <Ribbon/>
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => <route.component {...props}/>}
                            />
                        ) : null;
                    })}
                    <Redirect from="/" to="/misc/404"/>
                    {/* <Redirect from="/" to="/dashboard/analytics" /> */}
                </Switch>
            </div>

            <Footer/>
            <Shortcut/>
        </div>
    );

}



