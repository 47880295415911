const TastingTecnicalConfig = [
    {
        title: "Esame Visivo",
        col: 6,
        childCol: 12,
        icon: 'eye',
        initial: 0,
        data: {
            'clearness':["velato","abbastanza limpido","limpido","cristallino","brillante"],
            'quantity':["trasparente","poco fitto","abbastanza fitto","fitto", "molto fitto"],
            'vivacity':["spento","poco vivace","abbastanza vivace","vivace","molto vivace"]
        },
        context: 'visive',
        typologies: [
            {name: 'Aspetto', value: 'appareance'},
            {name: 'Colore', value: 'color'}
        ],
        ratio: {}
    }, {
        title: "Esame Olfattivo",
        col: 6,
        childCol: 12,
        icon: 'leaf',
        initial: 0,
        data: {
            'smell_intensity':["carente","poco intenso","abbastanza intenso","intenso","molto intenso"],
            'complexity':["carente","poco complesso","abbastanza complesso","complesso","ampio"],
            'smell_quality':["comune","poco fine","abbastanza fine","fine","eccellente"],
        },
        context: 'olfactive',
        typologies: [
            {name: 'Qualità', value: 'quality'},
            {name: 'Intensità', value: 'intensity'}
        ],
        ratio: {}

    },
    {
        title: "Esame Gusto-olfattivo",
        col: 12,
        childCol: 4,
        icon: 'glass',
        initial: 0,
        data: {
            'sugars':["secco","abboccato","amabile","dolce","stucchevole"],
            'alcohols':["leggero","pococaldo","abbastanzacaldo","caldo","alcolico"],
            'polyalcohols':["spigoloso","pocomorbido","abbastanzamorbido","morbido","pastoso"],
            'acids':["piatto","pocofresco","abbastanzafresco","fresco","acidulo"],
            'tannins':["molle","pocotannico","abbastanzatannico","tannico","astringente"],
            'mineral':["magro","debole","dicorpo","robusto","pesante"],
            'structure':["scipito","pocosapido","abbastanzasapido","sapido","salato"],
            'balance':["poco equilibrato","abbastanza equilibrato","equilibrato"],
            'taste_intensity':["carente","poco intenso","abbastanza intenso","intenso","molto intenso"],
            'persistence':["corto","poco persistente","abbastanza persistente","persistente","molto persistente"],
            'taste_quality':["comune","poco fine","abbastanza fine","fine","eccellente"]
        },
        context: "tasting-olfactive",
        typologies: [
            {name: 'Struttura', value: 'structure'},
            {name: 'Persistenza', value: 'persistence'},
            {name: 'Intensità', value: 'intensity'},
            {name: 'Tannino', value: 'tannin'}
        ],
        ratio: {}
    },
    {
        title: "Considerazioni generali",
        col: 12,
        childCol: 6,
        icon: 'list-ul',
        initial: 0,
        data: {
            'evolution':["immaturo","giovane","pronto","maturo","vecchio"],
            'armony':["poco armonico","abbastanza armonico","armonico"]
        },
        context: "general-consideration",
        typologies: [
            {name: 'Evoluzione', value: 'evolution'},
            {name: 'Giudizio', value: 'judgement'}
        ],
        ratio: {}

    }
];

export default TastingTecnicalConfig;