import React, {useEffect, useState} from "react";
import {HashRouter, Redirect, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/configureStore";
import {authRoutes} from "./routes";
import {Layout} from "./common/layout";


export default function App() {

    const [logged, setLogged] = useState(localStorage.getItem('user'));

    useEffect(() => {

        if (logged == null) {
            setLogged(false);
        } else {
            let user = JSON.parse(logged);

            if (user != null && user.access_token != "") {
                setLogged(true);
            } else {
                setLogged(false);
            }
        }


    }, [logged])

    return (
        <Provider store={store}>
            <HashRouter>
                <Switch>
                    {authRoutes.map((route, idx) => {
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => <route.component {...props} />}
                            />
                        ) : null;
                    })}
                    <Route
                        render={({location}) =>
                            logged ? (
                                <Route path="/" name="Home" component={Layout}/>
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: "/login",
                                        state: {from: location}
                                    }}
                                />
                            )
                        }
                    />
                </Switch>
            </HashRouter>
        </Provider>
    );
}
