import React from "react";
import Datatable from "../../../../common/tables/components/Datatable";
import AppSettings from "../../../../config/AppSettings";

export default class UserListTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: props.user
        }
    }

    render() {

        let _this = this;

        return (
            <Datatable
                options={{
                    ajax: AppSettings.url + "/api/user?api_token=" + _this.state.user.access_token,
                    columns: [
                        {data: "name"},
                        {data: "username"},
                        {data: "email"},
                        {data: "level"},
                        {data: "created_at"},
                        {data: "updated_at"},
                    ]

                }}
                paginationLength={true}
                className="table table-striped table-bordered table-hover"
                width="100%">
                <thead>
                <tr>

                    <th data-class="expand">
                        <i className="fa fa-fw text-muted hidden-md hidden-sm hidden-xs"/>
                        Nome
                    </th>
                    <th>
                        <i className="fa fa-fw fa-tag text-muted hidden-md hidden-sm hidden-xs"/>
                        Utente
                    </th>
                    <th>
                        <i className="fa fa-fw fa-tag text-muted hidden-md hidden-sm hidden-xs"/>
                        E-mail
                    </th>
                    <th>
                        <i className="fa fa-fw fa-tag text-muted hidden-md hidden-sm hidden-xs"/>
                        Livello
                    </th>
                    <th data-class="expand">
                        <i className="fa fa-fw fa-calendar text-muted hidden-md hidden-sm hidden-xs"/>
                        Inserito il
                    </th>
                    <th data-class="expand">
                        <i className="fa fa-fw fa-calendar text-muted hidden-md hidden-sm hidden-xs"/>
                        Ultimo aggiornamento
                    </th>
                </tr>
                </thead>
            </Datatable>
        );
    }


}
